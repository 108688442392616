<template>
  <div
    id="testimonials"
    class="h-full">
    <div class="relative flex h-full overflow-hidden rounded-xl">
      <NuxtImg
        src="/images/hauler-one.jpg"
        sizes="100vw md:800px"
        fit="cover"
        quality="85"
        alt="hauler"
        class="absolute z-10 size-full object-cover" />

      <!-- Text -->
      <div
        class="absolute bottom-6 left-1/2 z-10 h-fit w-[90%] -translate-x-1/2 flex-col rounded-xl border bg-white/95 p-3 shadow-2xl backdrop-blur-sm md:bottom-8 md:p-6">
        <Transition
          name="slide-up"
          mode="out-in">
          <div
            :key="testimonial.id"
            class="flex flex-col gap-0 md:gap-3">
            <div class="flex items-center justify-between">
              <div class="flex">
                <Icon
                  v-for="i in 5"
                  :key="i"
                  name="heroicons:star-solid"
                  class="text-xl text-emerald-600 md:text-2xl" />
              </div>
              <Icon
                name="logos:google"
                class="h-8 text-7xl" />
            </div>
            <div>
              <p class="text-sm font-medium md:text-xl">
                {{ testimonial.content }}
              </p>
              <p class="mt-2 text-sm text-gray-600 md:text-lg">
                {{ testimonial.name }}
              </p>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const client = useSupabaseClient()
const testimonial = ref({})
let intervalId: NodeJS.Timeout | null = null

// Fetch all testimonials
const { data: testimonials } = await useAsyncData('testimonials', async () => {
  const { data, error } = await client.from('testimonials').select('*')
  if (error) {
    console.warn(error)
    throw error
  }

  return data
})

// Get a random testimonial
async function getTestimonial(): Promise<void> {
  function updateTestimonial() {
    testimonial.value = (testimonials.value as Array<any>)?.[Math.floor(Math.random() * testimonials.value.length)]
  }

  // Update testimonial immediately
  updateTestimonial()

  // Clear the previous interval if it exists
  if (intervalId) {
    clearInterval(intervalId)
  }

  // Update testimonial every 5 seconds
  intervalId = setInterval(updateTestimonial, 5000)
}

onMounted(() => {
  getTestimonial()
})

onBeforeUnmount(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
})
</script>

<style>
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.25s ease-out;
  }

  .slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
</style>
